<template>
  <v-card>
    <v-card-title class="msaBlue white--text">
      Document Upload History
    </v-card-title>
    <v-data-table
      :items="items"
      :headers="headers"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:[`item.originalFilename`]="{ item }">
        <span
          @click="downloadDocument(item)"
          class="msaBlue--text"
          :style="{ cursor: 'pointer' }"
        >
          {{ item.originalFilename }}
        </span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | filterAsLocalDate }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'TrainingRecordHistory',
  props: {
    trainingRecordId: {
      type: Number,
    },
  },
  data() {
    return {
      items: [],
      headers: [
        {
          text: 'Rev#',
          value: 'revision',
          class: 'lightGrey',
          width: '30px',
          align: 'center',
        },
        {
          text: 'Filename',
          value: 'originalFilename',
          class: 'lightGrey',
        },
        {
          text: 'Archived Date',
          value: 'createdAt',
          width: '110px',
          class: 'lightGrey',
          align: 'center',
        },
        {
          text: 'Archived By',
          value: 'createdByFullname',
          width: '100px',
          class: 'lightGrey',
          align: 'center',
        },
      ],
      loading: false,
    };
  },
  methods: {
    getHistory() {
      this.loading = true;
      const params = {
        id: this.trainingRecordId,
        loaderText: 'loading...',
      };
      const url = 'get-training-record-history?format=json';
      this.$axios
        .post(url, params)
        .then(response => {
          this.loading = false;
          this.items = response.data;
        })
        .catch(error => error);
    },
    downloadDocument(doc) {
      const params = {
        id: doc.id,
        isArchived: true,
        loaderText: 'loading...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('download-training-record?format=json', params, options)
        .then(response => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', doc.originalFilename + '.pdf');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.$root.showMessage(
              'File not found',
              'File does not exist.',
              () => false,
              null,
              'OK',
            );
          } else {
            return error;
          }
        });
    },
  },
  mounted() {
    this.getHistory();
  },
};
</script>

<style></style>
